import { Popover } from '@headlessui/react'
import { EllipsisVerticalIcon, FlagIcon, ShoppingBagIcon, UserCircleIcon, UsersIcon } from '@heroicons/react/24/outline'
import { useLogoutModal } from 'hooks/common/useLogoutModal'
import Link from 'next/link'
import { useCallback } from 'react'
import useAppLayout from 'shared/hooks/useAppLayout'
import LoginAsIcon from 'shared/icons/LoginAsIcon'
import { useBoolean } from 'usehooks-ts'
import dynamic from 'next/dynamic'
import { InviteFormType } from 'types/V3/User'
import inviteCollector from 'shared/services/inviteCollector'
import { notifySuccess } from 'utils/toast'

const InviteModal = dynamic(() => import('../modal/InviteModal'))

type SideBarPopoverProps = {
  isDesktopSidebar: boolean
  isUserCreator?: boolean
}

const SideBarPopover = (props: SideBarPopoverProps) => {
  const { isDesktopSidebar, isUserCreator } = props
  const { showLogoutModal } = useLogoutModal()
  const { toggleMobileSidebar } = useAppLayout()

  const handleItemClick = useCallback(() => {
    if (isDesktopSidebar) return // dont trigger the mobile sidebar if it's a desktop sidebar
    toggleMobileSidebar()
  }, [toggleMobileSidebar, isDesktopSidebar])

  const {
    value: isInviteCollectorModalVisible,
    setTrue: showInviteCollectorModalVisible,
    setFalse: hideInviteCollectorModalVisible,
  } = useBoolean(false)

  const handleInviteCollector = useCallback(
    async (data: InviteFormType) => {
      await inviteCollector(data)
      hideInviteCollectorModalVisible()
      notifySuccess('Invitation sent!')
    },
    [hideInviteCollectorModalVisible],
  )

  return (
    <Popover className="relative w-full">
      <Popover.Button className="mb-0 flex w-full justify-start gap-2 bg-white  px-4 py-2 focus:outline-0 lg:px-8">
        <EllipsisVerticalIcon className="h-6 w-6" />
        <span> More</span>
      </Popover.Button>

      <Popover.Panel className="absolute bottom-10 left-1 z-[100] w-[260px] rounded-[12px] border border-black/10 shadow-cohart-sm backdrop-blur-xl lg:bottom-14 lg:left-4 lg:w-[289px]">
        <div className="flex w-full flex-col gap-2 border-b border-black/10 px-5 pt-2 lg:px-8 lg:pb-4 lg:pt-[18px]">
          <Link passHref href="/settings/account" className="flex items-center gap-2 py-2" onClick={handleItemClick}>
            <UserCircleIcon className="h-6 w-6" />
            <span className="text-sm font-medium">Account</span>
          </Link>

          {/* show purchases here for creator */}
          {isUserCreator && (
            <Link passHref href="/purchases" className="flex items-center gap-2 py-2" onClick={handleItemClick}>
              <ShoppingBagIcon className="h-6 w-6" />
              <span className="text-sm font-medium">Purchases</span>
            </Link>
          )}
        </div>

        <div className="flex w-full flex-col gap-2 border-b border-black/10 px-5 lg:px-8 lg:py-4">
          <Link
            passHref
            href="/settings/invite-friends"
            className="flex items-center gap-2 py-2"
            onClick={handleItemClick}
          >
            <UsersIcon className="h-6 w-6" />
            <span className="text-sm font-medium">Invite Friends</span>
          </Link>

          {isUserCreator && (
            <button className="flex items-center gap-2 py-2" onClick={showInviteCollectorModalVisible}>
              <UsersIcon className="h-6 w-6" />
              <span className="text-sm font-medium">Invite My Collectors</span>
            </button>
          )}

          <Link passHref href="/settings/report" className="flex items-center gap-2 py-2" onClick={handleItemClick}>
            <FlagIcon className="h-6 w-6" />
            <span className="text-sm font-medium">Report Content & Feedback</span>
          </Link>
        </div>

        <div className="flex w-full flex-col gap-2 px-5 pb-1 pt-1 lg:px-8 lg:pb-[18px] lg:pt-4">
          <button className="flex items-center gap-2 py-2" onClick={showLogoutModal}>
            <LoginAsIcon className="h-6 w-6 text-black" color="black" />
            <span className="text-sm font-medium">Logout</span>
          </button>
        </div>
      </Popover.Panel>

      {isInviteCollectorModalVisible && (
        <InviteModal
          invitedRole="collector"
          onClose={hideInviteCollectorModalVisible}
          onSubmit={handleInviteCollector}
        />
      )}
    </Popover>
  )
}

export default SideBarPopover
